import React, {useState} from 'react';
import Layout from "../components/layout";
import { useGetDashboardQuery } from "../services/trades";
import DashboardContainer from "../components/dashboardContainer";
import DataLoadHandler from "../components/helpers/dataloadhandler";
import {getDashboardConfig} from "../config/dashboard.config";
import DateRangePickerComponent from "../components/DateRangePickerComponent";
import moment from "moment";
import Statistics from "../components/Statistics";
import {useGetMonthlyBalanceQuery} from "../services/balance-history";
import DistributedColumnChart from "../components/charts/DistributedColumnChart";
import StatCard from "../components/statcard";
import PieChart from "../components/charts/piechart";
import {
    BsBarChart,
    BsCaretLeftFill,
    BsExclamationTriangle,
    BsGraphUp, BsQuestionCircle
} from "react-icons/bs";
import {useGetCurrentHourDataQuery} from "../services/market-journal";
import {Table} from "react-bootstrap";
import MarketStatistics from "../components/MarketStatistics";
import WelcomeBanner from "../components/WelcomeBanner";


const Dashboard = () => {
    const [startDate, setStartDate] = useState(moment().subtract(30, 'days'));
    const [sendStartDate, setSendStartDate] = useState(moment().subtract(30, 'days').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment());
    const [sendEndDate, setSendEndDate] = useState(moment().format('YYYY-MM-DD'));

    const { data, isLoading, isError, refetch } = useGetDashboardQuery({startDate: sendStartDate, endDate: sendEndDate});
    const { data: historyData } = useGetMonthlyBalanceQuery('');
    const { data: currentData} = useGetCurrentHourDataQuery('');

    const summaryData = {
        summaryToday: data?.summaryToday,
        summaryWeek: data?.summaryWeek,
        summaryMonth: data?.summaryMonth
    };

    const buysSellsData = [
        { name: 'Buys', value: data?.totalBuys },
        { name: 'Sells', value: data?.totalSells }
    ];

    const winRatioData = [
        { name: 'Wins', value: data?.win_ratio },
        { name: 'Losses', value: 100 - data?.win_ratio }
    ];

    const statsConfig = [
        { title: "Total Trades", data: data?.totalTrades, IconComponent: BsCaretLeftFill, bg: "light", textColor: "black" },
        { title: "Pips", data: data?.totalPips, IconComponent: BsGraphUp, bg: "light", textColor: "black" },
        { title: "Outcome", data: `$ ${data?.totalOutcome}`, IconComponent: BsBarChart, bg: "light", textColor: "black" },
        { title: "Mistakes", data: data?.totalMistakes, IconComponent: BsExclamationTriangle, bg: "light", textColor: "black" },
        { title: "Biggest Win", data: `$ ${data?.biggestWin}`, IconComponent: BsQuestionCircle, bg: "light", textColor: "black" },
        { title: "Biggest Loss", data: `$ ${data?.biggestLoss}`, IconComponent: BsQuestionCircle, bg: "light", textColor: "black" },
        { title: "Most Traded Pair", biggerCard: true, data: data?.mostTradedPair, IconComponent: BsQuestionCircle, bg: "light", textColor: "black" }
    ];

    const handleDatesChange = ({ startDate, endDate }: any) => {
        setStartDate(startDate);
        setSendStartDate(startDate.format('YYYY-MM-DD'));
        setEndDate(endDate);
        setSendEndDate(endDate.format('YYYY-MM-DD'));
        refetch();
    };

    const chartConfigs = getDashboardConfig(data);
    const singleChartConfigs = [getDashboardConfig(data)[0]];
    const singleChartConfigs2 = [getDashboardConfig(data)[1]];

    const categories = historyData?.categories;
    const chartData = [{
        name: "% Gain",
        data: historyData?.data
    }];

    return (
            <Layout>
                <DataLoadHandler isLoading={isLoading} isError={isError}>
                    <WelcomeBanner />
                    <div className="d-flex justify-content-between align-items-center mt-3 mb-2">
                        <h2>Dashboard</h2>
                        <div className="flex-shrink-0">
                            <DateRangePickerComponent
                                startDate={startDate}
                                endDate={endDate}
                                onDatesChange={handleDatesChange}
                            />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-lg-8">
                            {data &&
                                <div className="row">
                                    <div className="col-lg-3 mb-3">
                                        <PieChart
                                            chartPairData={buysSellsData}
                                            height={180}
                                            type='donut'
                                        />
                                    </div>
                                    {statsConfig.slice(0, 2).map((stat, index) => (
                                        <StatCard key={index} lgValue={4} {...stat} />
                                    ))}

                                    <div className="col-lg-3 mb-3">
                                        <PieChart
                                            chartPairData={winRatioData}
                                            height={180}
                                            type='donut'
                                        />
                                    </div>
                                    {statsConfig.slice(2, 4).map((stat, index) => (
                                        <StatCard key={index} lgValue={4} {...stat} />
                                    ))}
                                    {statsConfig.slice(4, 7).map((stat, index) => (
                                        <StatCard key={index} lgValue={4} {...stat} />
                                    ))}
                                    <Statistics summaryData={summaryData}/>
                                </div>
                            }
                            {/*<Stats*/}
                            {/*    data={data}*/}
                            {/*/>*/}
                        </div>
                        <div className="col-lg-4">
                            {singleChartConfigs.map((config: any, index: number) => (
                                <DashboardContainer
                                    key={index}
                                    title={config.title}
                                    component={config.component}
                                    props={config.props}
                                    className='col-lg-12'
                                />
                            ))}
                        </div>
                        <div className="col-lg-12">
                            {currentData &&
                                <MarketStatistics data={currentData}/>
                            }
                        </div>
                        <div className="col-lg-4">
                            {singleChartConfigs2.map((config: any, index: number) => (
                                <DashboardContainer
                                    key={index}
                                    title={config.title}
                                    component={config.component}
                                    props={config.props}
                                    className='col-lg-12'
                                />
                            ))}
                        </div>
                        <div className="col-lg-8">
                            <DistributedColumnChart
                                categories={categories}
                                series={chartData}
                                colorCondition={true}
                                chartTitle="Monthly % gain Overview"
                            />
                        </div>
                        {/*<div className="col-lg-6">*/}
                        {/*    <News />*/}
                        {/*</div>*/}
                    </div>
                    <div className="row">
                        {chartConfigs.slice(2).map((config: any, index: number) => (
                            <DashboardContainer
                                key={index}
                                title={config.title}
                                component={config.component}
                                props={config.props}
                                className={config.className}
                            />
                        ))}
                    </div>
                </DataLoadHandler>
            </Layout>
    );
}

export default Dashboard;
