import React, { useEffect, useState } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { BsX } from 'react-icons/bs';

const WelcomeBanner = () => {
    const [show, setShow] = useState(true);

    useEffect(() => {
        const hasSeenBanner = localStorage.getItem('hasSeenWelcomeBanner');
        if (hasSeenBanner) {
            setShow(false);
        }
    }, []);

    const handleClose = () => {
        setShow(false);
        localStorage.setItem('hasSeenWelcomeBanner', 'true');
    };

    if (!show) return null;

    return (
        <Alert variant="info" className="mb-3 d-flex justify-content-between align-items-center">
            <div>
            We're back! Development on the app was temporarily on hold, but we’re now fully committed to enhancing your experience.

            Your feedback is invaluable to us—whether it's suggestions for improvement or bug reports, we want to hear from you! You can submit feedback by selecting a user and choosing ‘Feedback,’ or report any issues directly via email at journal4x@gmail.com.

        Thank you for being a part of our journey. Your insights help us build a better experience for everyone!
            </div>
            <div className="d-flex align-items-center gap-2">
                <Link to="/feedback">
                    <Button variant="outline-primary" size="sm">
                        Share Feedback
                    </Button>
                </Link>
                <Button variant="link" size="sm" onClick={handleClose} className="p-0">
                    <BsX size={20} />
                </Button>
            </div>
        </Alert>
    );
};

export default WelcomeBanner; 